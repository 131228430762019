import clsx from 'clsx';
import { Button } from '../Button/Button';
import { Warning } from '../Warning';
type Button = {
  text: string;
  link?: {
    href: string;
    target?: '_self' | '_blank';
  };
  variant?: 'filled' | 'subtle' | 'outlined';
  size?: 'default' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary';
  showIcon?: boolean;
};
type Props = {
  className?: string;
  alignment?: 'left' | 'center' | 'right';
  alignmentMobile?: 'left' | 'center' | 'right';
  buttonGap: number;
  buttons: Button[];
};
export function ButtonGroup({
  className,
  buttons,
  alignment = 'left',
  alignmentMobile = 'left',
  buttonGap
}: Props) {
  if (buttons.length === 0) return <Warning className={className}>There are no buttons</Warning>;
  return <div className={clsx(className, 'flex max-sm:flex-col flex-wrap', {
    left: 'md:justify-start md:items-start ',
    right: 'md:justify-end md:items-end',
    center: 'md:justify-center md:items-center'
  }[alignment], {
    left: 'justify-start items-start',
    right: 'justify-end items-end',
    center: 'justify-center items-center'
  }[alignmentMobile])} style={{
    gap: buttonGap
  }} data-sentry-component="ButtonGroup" data-sentry-source-file="ButtonGroup.tsx">
			{buttons?.map((button, i) => {
      return <Button key={i} variant={button.variant} color={button.color} size={button.size} link={button.link} showIcon={button.showIcon}>
						{button.text}
					</Button>;
    })}
		</div>;
}